/* #region CONTACT */
/*=============== CONTACT ===============*/
.contact__container {
    row-gap: 3rem;
    padding-bottom: 3rem;
}

.contact__title {
    text-align: center;
    font-size: var(--h3-font-size);
    margin-bottom: 1.5rem;
}

.contact__info {
    display: grid;
    gap: 1rem;
}

.contact__card {
    background-color: var(--container-color);
    padding: 2.5rem;
    border-radius: .75rem;
    text-align: center;
}

.contact__card-icon {
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: .25rem;
}

.contact__card-title,
.contact__card-data {
    font-size: var(--small-font-size);
}

.contact__card-title {
    font-weight: var(--font-medium);
}

.contact__card-data {
    display: block;
    margin-top: 30px;
}

.contact__button {
    color: var(--first-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    column-gap: .25rem;
    margin-top: 5px;
}

.contact__button:hover .contact__button-icon {
    transform: translateX(.25rem);
}

.contact__button-icon {
    font-size: 1rem;
    transition: .4s;
}

.contact__form-div {
    position: relative;
    margin-bottom: 2rem;
    height: 4rem;
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--text-color-light);
    background: none;
    color: var(--text-color);
    outline: none;
    padding: 1.5rem;
    border-radius: .75rem;
    z-index: 1;
}

.contact__form-tag {
    position: absolute;
    top: -.75rem;
    left: 1.25rem;
    font-size: var(--small-font-size);
    padding: .25rem;
    background-color: var(--body-color);
    z-index: 10;
}

.contact__form-area {
    height: 11rem;
}

.contact__form-area textarea {
    resize: none;
}

.contact__form-sendMessageButtonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* #endregion */

/* For medium devices */
@media screen and (min-width: 576px) {
    .contact__info {
        grid-template-columns: 300px;
        justify-content: center;
    }

    .contact__form {
        width: 360px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 767px) {
    .contact__container {
        grid-template-columns: repeat(2, max-content);
        justify-content: center;
        column-gap: 3rem;
    }
}

/* For large devices */
@media screen and (min-width:992px) {
    .contact__container {
        column-gap: 6rem;
    }

}