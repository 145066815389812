/* #region THEME */
/*=============== THEME ===============*/
.change-theme {
    font-size: 1.25rem;
    cursor: pointer;
    transition: .3s;
}

.change-theme:hover {
    color: var(--first-color);
}

/*========== Variables Light theme ==========*/
body.light-theme {
    --title-color: hsl(var(--second-hue), 15%, 15%);
    --text-color: hsl(var(--second-hue), 8%, 35%);
    --body-color: hsl(var(--second-hue), 100%, 99%);
    --container-color: #fff;
}

/*========== 
      Color changes in some parts of 
      the website, in light theme
  ==========*/
.light-theme .scroll-header {
    box-shadow: 0 2px 4px hsla(0, 0%, 1%, .1);
}

.light-theme .nav__menu {
    background-color: hsla(var(--second-hue), 32%, 90%, .8);
}

.light-theme .section__subtitle {
    color: var(--text-color);
}

.light-theme .home__social-link {
    box-shadow: 0 2px 8px hsla(var(--second-hue), 48%, 8%, .1);
}

.light-theme .home__social::after,
.light-theme .footer__social-link {
    background-color: var(--title-color);
}

.light-theme .home__social-link,
.light-theme .home__scroll,
.light-theme .button,
.light-theme .button:hover,
.light-theme .active-work,
.light-theme .footer__title,
.light-theme .footer__link,
.light-theme .footer__copy {
    color: var(--title-color);
}

.light-theme .about__box {
    box-shadow: 0 2px 8px hsla(var(--second-hue), 48%, 8%, .1);
}

.light-theme .skills__content,
.light-theme .services__card,
.light-theme .work__card,
.light-theme .testimonial__card,
.light-theme .contact__card {
    box-shadow: 0 2px 16px hsla(var(--second-hue), 48%, 8%, .1);
}

.light-theme::-webkit-scrollbar {
    background-color: hsl(var(--second-hue), 8%, 66%);
}

.light-theme::-webkit-scrollbar-thumb {
    background-color: hsl(var(--second-hue), 8%, 54%);
}

.light-theme::-webkit-scrollbar-thumb:hover {
    background-color: hsl(var(--second-hue), 8%, 44%);
}

/* #endregion */