/* #region FONTS */
/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
/* #endregion */

/* #region VARIABLES */
/*=============== VARIABLES CSS ===============*/
:root{
    --header-height: 3.5rem;

    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
    /*
        Purple: hsl(250, 66%, 75%)
        Blue: hsl(207, 90%, 72%)
        Pink: hsl(356, 66%, 75%)
        Teal: hsl(174, 63%, 62%)
        Orange Peel: hsl(36 , 100%, 50%)
        Amber: hsl(45 , 100% , 51%)
        Las Palmas: hsl(66 , 70% , 54%)
        Niagara: hsl(174 , 63% , 40%)
        Summer Sky: hsl(187 , 71% , 50%)
        Havelock Blue: hsl(231 , 44% , 56%)
        Light Slate Grey: hsl(200 , 15% , 54%)
        Outrageous Orange: hsl(14 , 100% , 57%)
  */
    --first-hue: 174;
    --sat: 63%;
    --lig: 62%;
    --second-hue: 219;
    --first-color: hsl(var(--first-hue), var(--sat), var(--lig));
    --first-color-alt: hsl(var(--first-hue), var(--sat), 58%);
    /* -4% */
    --title-color: hsl(var(--second-hue), 15%, 95%);
    --text-color: hsl(var(--second-hue), 8%, 75%);
    --text-color-light: hsl(var(--second-hue), 4%, 55%);
    --body-color: hsl(var(--second-hue), 48%, 8%);
    --container-color: hsl(var(--second-hue), 32%, 12%);

    /*========== Font and typography ==========*/
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font: 'Poppins', sans-serif;
    --biggest-font-size: 1.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;
    --tiny-font-size: .625rem;

    /*========== Font weight ==========*/
    --font-medium: 500;
    --font-semibold: 600;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}

/* Responsive typography */
@media screen and (min-width: 968px) {
    :root {
        --biggest-font-size: 2.5rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}

/* #endregion */

/* #region BASE */
/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body,
button,
input,
textarea {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

body {
    background-color: var(--body-color);
    color: var(--text-color);
    transition: .4s;
    /* for light mode animation */
}

h1,
h2,
h3 {
    color: var(--title-color);
    font-weight: var(--font-semibold);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
    border: none;
    outline: none;
}

img {
    max-width: 100%;
    height: auto;
}

/* #endregion BASE*/

/* #region REUSABLECSSCLASSES */
/*=============== REUSABLE CSS CLASSES ===============*/
.container {
    max-width: 968px;
    margin-left: 1rem;
    margin-right: 1rem;
}

.grid {
    display: grid;
    gap: 1.25rem;
}

.main {
    overflow: hidden;
}

.section {
    padding: 4.5rem 0 1rem;
}

.section__title,
.section__subtitle {
    text-align: center;
}

.section__title {
    font-size: var(--h2-font-size);
    color: var(--first-color);
    margin-bottom: 2rem;
}

.section__subtitle {
    display: block;
    font-size: var(--smaller-font-size);
    color: var(--text-color-light);
}

/* #endregion */

/* For large devices */
@media screen and (min-width:992px) {
    .container {
        margin-left: auto;
        margin-right: auto;
    }

    .section {
        padding: 6.5rem 0 1rem;
    }

    .section__title {
        margin-bottom: 3.5rem;
    }
}