/* #region TESTIMONIAL */
/*=============== TESTIMONIAL ===============*/
.testimonial__card {
    background-color: var(--container-color);
    padding: 1.25rem 1.5rem;
    border-radius: 1.5rem;
    margin-bottom: 3rem;
}

.testimonial__img {
    width: 60px;
    border-radius: 3rem;
    margin-bottom: 1rem;
}

.testimonial__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: .25rem;
}

.testimonial__description {
    font-size: var(--small-font-size);
}

/* Swiper class */
.swiper-pagination-bullet {
    background-color: var(--text-color-light);
}

.swiper-pagination-bullet-active {
    background-color: var(--first-color);
}

/* #endregion */

/* For large devices */
@media screen and (min-width:992px) {

    .testimonial__container {
        width: 750px;
    }

    .testimonial__card {
        padding: 1.5rem 2rem;
    }
}