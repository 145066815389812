/* #region ABOUT */
/*=============== ABOUT ===============*/
.about {
    padding-top: 7.5rem;
}

.about__container {
    row-gap: 2.5rem;
}

.about__img {
    width: 220px;
    border-radius: 1.5rem;
    justify-self: center;
}

.about__data {
    text-align: center;
}

.about__info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .5rem;
    margin-bottom: 2rem;
}

.about__box {
    background-color: var(--container-color);
    border-radius: .75rem;
    padding: .75rem .5rem;
}

.about__icon {
    font-size: 1.5rem;
    color: var(--first-color);
    margin-bottom: .5rem;
}

.about__title {
    font-size: var(--small-font-size);
}

.about__subtitle {
    font-size: var(--tiny-font-size);
}

.about__description {
    margin-bottom: 2rem;
}

/* #endregion */

/* For small devices */
@media screen and (max-width: 320px) {
    .about__info {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For medium devices */
@media screen and (min-width: 576px) {
    .about__info {
        grid-template-columns: repeat(3, 140px);
        justify-content: center;
    }

    .about__description {
        padding: 0 5rem;
    }
}


/* For large devices */
@media screen and (min-width:992px) {
    .about__container {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 4rem;
    }

    .about__img {
        width: 350px;
    }

    .about__data {
        text-align: initial;
    }

    .about__info {
        justify-content: initial;
    }

    .about__box {
        text-align: center;
        padding: 1rem 1.25rem;
    }

    .about__description {
        padding: 0 4rem 0 0;
        margin-bottom: 2.5rem;
    }

}