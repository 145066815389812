/* #region BUTTONS */
/*=============== BUTTONS ===============*/
.button {
    display: inline-block;
    background-color: var(--first-color);
    color: var(--body-color);
    padding: .75rem 1rem;
    border-radius: .5rem;
    font-weight: var(--font-medium);
    transition: .4s;
}

.button:hover {
    background-color: var(--first-color-alt);
    color: white !important;
    box-shadow: inset 0px 0px 10px white;
}

.button--ghost {
    background-color: transparent;
    border: 2px solid var(--first-color);
    color: var(--first-color);
}

/* #endregion */