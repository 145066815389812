/* #region HEADERNAV */
/*=============== HEADER & NAV===============*/
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    z-index: var(--z-fixed);
    transition: .4s;
    /* for light mode animation */
}

.nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav h1 {
    font-size: 1rem;
}

.nav__logo {
    color: var(--first-color);
    font-weight: var(--font-medium);
    transition: .4s;
}

.nav__logo:hover {
    color: var(--first-color-alt);
}

.nav__menu {
    position: fixed;
    bottom: 1rem;
    background-color: hsla(var(--second-hue), 32%, 16%, .8);
    width: 90%;
    border-radius: 4rem;
    padding: 1rem 2.25rem;
    backdrop-filter: blur(10px);
    transition: .4s;
    /* for light mode animation */
}

.nav__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__link {
    color: var(--text-color);
    font-size: 1.25rem;
    padding: .4rem;
    display: flex;
    border-radius: 5rem;
}

.nav__link:hover:not(.active-link) {
    background: linear-gradient(180deg,
            hsla(var(--first-hue), var(--sat), var(--lig), 1),
            hsla(var(--first-hue), var(--sat), var(--lig), .2));
    box-shadow: 0 0 16px hsla(var(--first-hue), var(--sat), var(--lig), .4);
    color: var(--title-color);
}

/* Active link */
.active-link {
    background: linear-gradient(180deg,
            hsla(var(--first-hue), var(--sat), var(--lig), 1),
            hsla(var(--first-hue), var(--sat), var(--lig), .2));
    box-shadow: 0 0 16px hsla(var(--first-hue), var(--sat), var(--lig), .4);
    color: var(--title-color);
}

/* Change background header */
.scroll-header {
    box-shadow: 0 4px 4px hsla(0, 0%, 4%, .3);
}

/* #endregion */

/* For small devices */
@media screen and (max-width: 320px) {
    .nav__menu {
        padding: 1rem 1.5rem;
    }
}

/* For medium devices */
@media screen and (min-width: 576px) {
    .nav__menu {
        width: 328px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

/* For large devices */
@media screen and (min-width:992px) {
    .nav {
        height: calc(var(--header-height) + 1rem);
    }
}