::-webkit-scrollbar {
    width: .6rem;
    height: .6rem;
    border-radius: .5rem;
    background-color: hsl(var(--second-hue), 8%, 38%);
}

::-webkit-scrollbar-thumb {
    background-color: hsl(var(--second-hue), 8%, 26%);
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover {
    background-color: hsl(var(--second-hue), 8%, 20%);
}