/* #region FOOTER */
/*=============== FOOTER ===============*/
.footer {
    background-color: var(--first-color);
}

.footer__container {
    padding: 2rem 0 6rem;
}

.footer__title,
.footer__link {
    color: var(--body-color);
}

.footer__title {
    text-align: center;
    margin-bottom: 1rem;
}

.footer__list {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: 2rem;
}

.footer__social {
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}

.footer__social-link {
    background-color: var(--body-color);
    color: var(--first-color);
    padding: .25rem;
    border-radius: .25rem;
    font-size: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.footer__social-link:hover {
    color: white;
}

.footer__social-link[title="GitHub"]:hover {
    background-color: #3a3a3a;
}

.footer__social-link[title="HackerRank"]:hover {
    background-color: #32c766;
}

.footer__social-link[title="Stack Overflow"]:hover {
    background-color: #e66c0b;
}

.footer__social-link[title="LinkedIn"]:hover {
    background-color: #0a66c2;
}

.footer__social-link[title="Instagram"]:hover {
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);;
}

.footer__social-link[title="HackerRank"] svg{
    width: 20px;
}

.footer__social-link[title="HackerRank"] svg path {
    fill: var(--first-color);
}

.footer__social-link[title="HackerRank"]:hover svg path {
    fill: white;
}

.footer__copy {
    display: block;
    margin-top: 1.5rem;
    color: var(--container-color);
    text-align: center;
    font-size: var(--smaller-font-size);
}

/* #endregion */

/* For large devices */
@media screen and (min-width:992px) {
    .footer__social-link {
        font-size: 1.25rem;
        padding: .4rem;
    }
}