/* #region WORK */
/*=============== WORK ===============*/
.work__container {
    padding-top: 1rem;
    height: 700px;
    overflow-y: scroll;
    width: 800px;
    --second-hue: 219;
}

.work__container:has(> :last-child:nth-child(1)){
    width: 400px;
    height: 350px;
    gap: unset;
}

.work__container:has(> :last-child:nth-child(1))::-webkit-scrollbar{
    display: none;
}

.light-theme .work__container::-webkit-scrollbar {
    background-color: hsl(var(--second-hue), 8%, 66%);
}

.light-theme .work__container::-webkit-scrollbar-thumb {
    background-color: hsl(var(--second-hue), 8%, 54%);
}

.light-theme .work__container::-webkit-scrollbar-thumb:hover {
    background-color: hsl(var(--second-hue), 8%, 44%);
}

.work__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .75rem;
    margin-bottom: 2rem;
}

.work__item {
    cursor: pointer;
    color: var(--title-color);
    padding: .25rem .75rem;
    font-weight: var(--font-medium);
    border-radius: .5rem;
}

.work__card {
    background-color: var(--container-color);
    padding: 1rem;
    border-radius: 1rem;
    align-self: flex-start;
}

.work__img {
    border-radius: 1rem;
    margin-bottom: .75rem;
}

.work__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: .25rem;
}

.work__buttons {
    display: flex;
    justify-content: space-between;
}

.work__button {
    width: max-content;
    color: var(--first-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: .25rem;
}

.work__button.github {
    color: white;
    font-size: 20px;
}

.light-theme .work__button.github{
    color: black;
}

.work__button:hover {
    text-shadow: 0 0 5px white;
}

/* Active item work*/
.active-work {
    background-color: var(--first-color);
    color: var(--body-color);
}

/* #endregion */

/* For small devices */
@media screen and (max-width: 320px) {
    .work__item {
        font-size: var(--small-font-size);
    }

    .work__filters {
        column-gap: .25rem;
    }
}

/* For medium devices */
@media screen and (max-width: 575px) {
    .work__container,
    .work__container:has(> :last-child:nth-child(1)){
        justify-content: center;
        height: 250px;
        width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
    .work__card{
        width: 225px;
    }
    .work__filters{
        column-gap: 0;
    }
    .work__item{
        font-size: 0.75rem;
    }

    ::-webkit-scrollbar{
        width: 5px;
    }
}

@media screen and (max-width: 300px) {
    .work__container {
        height: 225px;
        width: 222px;
    }
    
    .work__card{
        width: 180px;
    }
}

@media screen and (min-width: 576px) {
    .work__container {
        justify-content: center;
        height: 300px;
        width: 350px;
        margin-left: auto;
        margin-right: auto;
    }
    .work__card {
        width: 300px;
    }
}

@media screen and (min-width: 767px) {
    .work__container {
        grid-template-columns: repeat(2, max-content);
        height: 625px;
        width: 725px;
        margin-left: auto;
        margin-right: auto;
    }
    .work__card {
        width: 325px;
    }
}

/* For large devices */
@media screen and (min-width:992px) {
    .work__container {
        gap: 3rem;
        width: 800px;
        height: 700px;
    }

    .work__card {
        padding: 1.25rem;
        width: 350px;
    }

    .work__img {
        margin-bottom: 1rem;
    }

    .work__title {
        margin-bottom: .5rem;
    }

}