.docs__container {
    padding-top: 1rem;
}

.docs__item {
    cursor: pointer;
    color: var(--title-color);
    padding: .25rem .75rem;
    font-weight: var(--font-medium);
    border-radius: .5rem;
}

.docs__card {
    background-color: var(--container-color);
    padding: 1rem;
    border-radius: 1rem;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.docs__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: .25rem;
}

.docs_show_all_button{
    display: block;
    margin: 3rem auto 0;
    max-width: fit-content;
}

.docs__description{
    font-size: var(--small-font-size);
    margin-bottom: 0.5rem;
}

.docs__buttons {
    display: flex;
    justify-content: space-between;
}

.docs__button {
    width: max-content;
    color: var(--first-color);
    font-size: var(--normal-font-size);
    display: flex;
    align-items: center;
    column-gap: .25rem;
}

.docs__button:hover {
    text-shadow: 0 0 5px white;
}

.docs__button.github,
.disabled_link i{
    color: white;
    font-size: 20px;
}

.light-theme .docs__button.github,
.light-theme .disabled_link i{
    color: black;
}

.disabled_link{
    cursor: not-allowed;
    opacity: 0.5;
}

/* For small devices */
@media screen and (max-width: 320px) {
    .docs__item {
        font-size: var(--small-font-size);
    }
}


/* For medium devices */
@media screen and (min-width: 576px) {
    .docs__container {
        justify-content: center;
    }
}

@media screen and (min-width: 767px) {
    .docs__container {
        grid-template-columns: repeat(2, max-content);
    }
}

/* For large devices */
@media screen and (min-width:992px) {
    .docs__container {
        gap: 3rem;
    }

    .docs__card {
        padding: 1.25rem;
    }

    .docs__title {
        margin-bottom: .5rem;
    }

}